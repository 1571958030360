<template>
  <div
    id="section9"
    class="fondoAzul"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4">
        <h3 class="text-center mb-3">
          <span
            v-for="(item, index) in title"
            :key="index"
          >
            <span v-if="item.type=='txt'">
              {{ item.value }}
            </span>
            <strong v-if="item.type=='strong'">
              {{ item.value }}
            </strong>
          </span>
        </h3>
        <h4>
          {{ $t("estrategiaDigital.section9.text") }}
        </h4>
        <br>
        <b-row class="mt-2 mb-2">
          <b-col
            v-for="(item, index) in lists1"
            :key="index"
            md="6"
            sm="12"
          >
            <b-row>
              <b-col cols="2">
                <b-img
                  :src="lists1Img[index]"
                  style="width: 5em;"
                />
              </b-col>
              <b-col>
                <h4>
                  <strong>
                    {{ item.title }}
                  </strong>
                </h4>
                <p>
                  {{ item.text }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <br>
        <b-row class="mt-2 mb-2">
          <b-col
            v-for="(item, index) in lists2"
            :key="index"
            md="4"
            sm="12"
            class="text-center"
          >
            <h4>
              <strong>
                {{ item.title }}
              </strong>
            </h4>
            <p>
              {{ item.text }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            lists1Img: [
                require("@/assets/images/digital/icono-experiencia-1.svg"),
                require("@/assets/images/digital/icono-experiencia-2.svg"),
                require("@/assets/images/digital/icono-experiencia-3.svg"),
                require("@/assets/images/digital/icono-experiencia-4.svg"),
                require("@/assets/images/digital/icono-experiencia-5.svg"),
                require("@/assets/images/digital/icono-experiencia-6.svg"),
                require("@/assets/images/digital/icono-experiencia-7.svg")
            ]
        }
    },
    computed: {
        title() {
            return this.$t("estrategiaDigital.section9.title");
        },
        lists1() {
            return this.$t("estrategiaDigital.section9.lists1");
        },
        lists2() {
            return this.$t("estrategiaDigital.section9.lists2");
        }
    }
}
</script>