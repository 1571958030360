<template>
  <div
    id="section10"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-2">
        <h3 class="text-center">
          <strong>
            {{ $t("estrategiaDigital.section10.title") }}
          </strong>
        </h3>
        <br>
        <b-row>
          <b-col
            v-for="(item, index) in lists"
            :key="index"
            md="3"
            sm="12"
          >
            <b-row>
              <b-col
                cols="2"
                class="p-1 "
              >
                <b-img :src="listsImg[index]" />
              </b-col>
              <b-col class="p-1 ">
                <strong class="h4">
                  {{ item.title }}  
                </strong>
              </b-col>
            </b-row>
            <br>
            <ul>
              <li
                v-for="(item1, index1) in item.lists"
                :key="index1"
                class="mb-1"
              >
                {{ item1 }}
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            listsImg:[
                require("@/assets/images/digital/intellectual-capital.svg")
                ,require("@/assets/images/digital/brand-capital.svg")
                ,require("@/assets/images/digital/social-capital.svg")
                ,require("@/assets/images/digital/career-capital.svg")
            ]
        }
    },
    computed: {
        lists() {
            return this.$t("estrategiaDigital.section10.lists");
        }
    },
}
</script>