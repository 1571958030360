<template>
  <div
    id="section2"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container>
        <b-row align-v="center">
          <b-col
            md="8"
            sm="12"
            class="text-center mb-2"
          >
            <i>{{ $t("estrategiaDigital.section2.colabora") }}</i>
            <br>
            <b-img
              :src="logo"
              style="width: 70%; height: auto; "
            />
          </b-col>
          <b-col
            md="4"
            sm="12"
            class="text-center mb-2"
          >
            <div class="cardd">
              <h4>
                <strong>{{ $t("estrategiaDigital.section2.tituloT") }}</strong>
              </h4>
              <div>
                <font-awesome-icon :icon="['far','calendar-alt']" />
                &nbsp;{{ $t("estrategiaDigital.section2.semana") }}
              </div>
              <div>
                <font-awesome-icon :icon="['far','clock']" />
                &nbsp;{{ $t("estrategiaDigital.section2.tiempo") }}
              </div>
              <br>
              <h4>
                <strong>{{ $t("estrategiaDigital.section2.tituloC") }}</strong>
              </h4>
              <div>
                {{ $t("estrategiaDigital.section2.fecha") }}
              </div>
              <div>
                {{ $t("estrategiaDigital.section2.proximo") }}
              </div>
              <hr>
              <h4>
                <strong>{{ $t("estrategiaDigital.section2.tituloP") }}</strong>
                <br>
                <strong>
                  {{ $t("estrategiaDigital.section2.precio") }}
                </strong>
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            logo: require("@/assets/images/aicad-business-school-logo.png")
        }
    },
}
</script>

<style lang="scss">
 #section2{
   .cardd{
     margin: 0 1em;
     padding: 1em;
     background: rgba($color: #000000, $alpha: 0.1)
   }
 } 
</style>