<template>
  <div
    id="section15"
    class="fondoGrisOscuro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4">
        <b-row align-v="center">
          <b-col
            md="3"
            sm="12"
          >
            <div class="p-2">
              <p class="mt-2">
                {{ $t("estrategiaDigital.section15.text1") }}
              </p>
              <b-img
                :src="img2"
                class="mb-2"
              />
            </div>
          </b-col>
          <b-col
            md="5"
            sm="12"
          >
            <p>
              {{ $t("estrategiaDigital.section15.text2") }}
            </p>
          </b-col>
          <b-col
            md="4"
            sm="12"
          >
            <div class="cardd cardd-outline">
              <font-awesome-icon
                :icon="['fas','question-circle']"
                size="3x"
              />
              <p>
                <span>{{ $t("estrategiaDigital.section15.text3") }}</span>
                <b-link v-scroll-to="'#contacto'">
                  {{ $t("estrategiaDigital.section15.btn") }}
                </b-link>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            img2: require("@/assets/images/AICAD_LOGO_TRANSPARENTE_NEGRO.fw.png")
        }
    },
}
</script>

<style lang="scss">
  #section15{
    .cardd-outline{
      background-color: rgba($color: #000000, $alpha: 0.1);
      border: 0.15em solid whitesmoke;
      border-radius: 1em;
      padding: 1.2em;
      text-align: center;
    }
    a{
      color: whitesmoke !important;
    }
  }
</style>