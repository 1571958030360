<template>
  <div
    id="section5"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container>
        <h3 class="text-center mt-3 mb-3">
          <strong>{{ $t("estrategiaDigital.section5.title") }}</strong>
        </h3>
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <b-row>
              <b-col
                md="3"
                sm="12"
              >
                <div class="imagenRedonda">
                  <b-img :src="imgProf" />
                </div>                
              </b-col>
              <b-col
                md="9"
                sm="12"
              >
                <h4>
                  {{ $t("estrategiaDigital.section5.text1") }}
                </h4>
                <h4>
                  <strong>{{ $t("estrategiaDigital.section5.autor") }}</strong>
                </h4>
                <i>
                  {{ $t("estrategiaDigital.section5.text2") }}
                </i>
              </b-col>
            </b-row>
            <br>
            <p
              v-for="(item, index) in texts"
              :key="index"
            >
              <span
                v-for="(item1, index1) in item.texts"
                :key="index1"
              >
                <span v-if="item1.type=='txt'">
                  {{ item1.value }}
                </span>
                <strong v-if="item1.type=='strong'">
                  {{ item1.value }}
                </strong>
                <i v-if="item1.type=='i'">
                  {{ item1.value }}
                </i>
              </span>
            </p>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <yt
              :idvideo="idurl"
              activo="false"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import yt from '../utils/youtube'
export default {
    components:{
        yt
    },
    data() {
        return {
            idurl: "VxQzJVzBdkk"
            , imgProf: require("@/assets/images/digital/anonimo.jpg")
        }
    },
    computed: {
        texts() {
            return this.$t("estrategiaDigital.section5.texts");
        }
    },
}
</script>

<style lang="scss">
  #section5{
    .imagenRedonda{
      border: 2px solid #ddd;
      border-radius: 6em;    
      width: 7em;
      height: 7em;

      img{
        width: auto;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 6em;   
      }

    }
  }
</style>