<template>
  <div
    id="section12"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-2">
        <h3 class="text-center">
          <strong>{{ $t("estrategiaDigital.section12.title") }}</strong>
        </h3>
        <br>
        <b-row>
          <b-col
            v-for="(item, index) in lists"
            :key="index"
            md="3"
            sm="6"
          >
            <div class="text-center">
              <div class="numRedondo">
                {{ item.val }}
              </div>
              <h3 class="mt-2">
                {{ item.title }}
              </h3>
              <p>
                {{ item.txt }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    computed: {
        lists() {
            return this.$t("estrategiaDigital.section12.lists");
        }
    },
}
</script>

<style lang="scss">
  #section12{
    .numRedondo{
      background-color: #015FFF;
      color: whitesmoke;
      font-size: 1.8em;
      font-weight: 600;
      border-radius: 5em;
      width: 1.5em;
      height: auto;
      text-align: center;
      margin: 0 auto;
    }
  }
</style>