<template>
  <div
    id="section4"
    class="fondoGris"
  >
    <b-container fluid>
      <b-container class="mt-2 mb-2">
        <h3 class="mb-3">
          <span
            v-for="(item, index) in title"
            :key="index"
          >
            <span v-if="item.type=='txt'">
              {{ item.value }}
            </span>
            <strong v-if="item.type=='strong'">
              {{ item.value }}
            </strong>
          </span>
        </h3>
        <div class="mb-1 mt-3 box">
          <b-img :src="img" />
        </div>
        <br>
        <div class="mt-1">
          <b-row align-h="center">
            <b-col
              v-for="(item, index) in texts"
              :key="index"
              md="3"
              sm="12"
              class="mb-3"
            >
              <div
                :class="(index < 2)?'lineaDivL':''"
                class="lineaDiv"
              >
                {{ item }}
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            img: require("@/assets/images/digital/working.jpg")
        }
    },
    computed: {
        title(){
            return this.$t("estrategiaDigital.section4.title");
        },
        texts(){
            return this.$t("estrategiaDigital.section4.texts");
        }
    },
}
</script>

<style lang="scss">
  #section4{
    text-align: center;
    .box {
      position: relative;
      height: 300px;
      width: 100%;
    }

    img{
      height: 300px;
      width: 100%;
      object-fit: cover;
      object-position: center center;
      margin-bottom: 1em;
    }

    .lineaDiv{
      height: calc(100%);
      padding-right: 1em;
      padding-bottom: 1em;
      padding-top: 1em;
    }

    .lineaDivL{
      border-right: 1px solid #2c3e50;
      @media (max-width: 768px) {
        border: 0;
        border-bottom: 1px solid #2c3e50;
      }
    }
  }
</style>