<template>
  <div
    id="section7"
    class="fondoGrisOscuro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4">
        <h3 class="text-center mb-3">
          <span
            v-for="(item, index) in title"
            :key="index"
          >
            <span v-if="item.type=='txt'">
              {{ item.value }}
            </span>
            <strong v-if="item.type=='strong'">
              {{ item.value }}
            </strong>
          </span>
        </h3>
        <b-row>
          <b-col
            md="4"
            sm="12"
          >
            <b-img :src="img" />
          </b-col>
          <b-col
            md="8"
            sm="12"
          >
            <b-row class="mt-2">
              <b-col
                v-for="(item, index) in lists"
                :key="index"
                md="6"
                sm="12"
              >
                <b-row class="mt-1 mb-2">
                  <b-col
                    cols="2"
                    style="margin: 0 auto;"
                  >
                    <div class="numRedondo">
                      {{ item.num }}
                    </div>
                  </b-col>
                  <b-col cols="9">
                    {{ item.txt }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            img: require("@/assets/images/digital/woman.jpg")
        }
    },
    computed: {
        title() {
            return this.$t("estrategiaDigital.section7.title");
        },
        lists() {
            return this.$t("estrategiaDigital.section7.lists");
        }
    },
}
</script>

<style lang="scss">
  #section7{
    .numRedondo{
      background-color: whitesmoke;
      color: gray;
      font-size: 1.8em;
      font-weight: 600;
      border-radius: 5em;
      width: 1.5em;
      height: auto;
      text-align: center;
    }
  }
</style>