<template>
  <div
    id="section6"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container>
        <hr class="mt-3">
        <h3 class="text-center mt-3 mb-3">
          <strong>{{ $t("estrategiaDigital.section6.title") }}</strong>
        </h3>
        <b-row align-h="center">
          <b-col
            md="6"
            sm="12"
          >
            <h4>
              {{ $t("estrategiaDigital.section6.text1") }}
            </h4>
            <div class="cardGris cardd">
              <div class="imagenRedonda cardd">
                <b-img :src="imgTutor" />
              </div>
              <h3 class="mt-3">
                {{ $t("estrategiaDigital.section6.nombre") }}
              </h3>
              <p>
                {{ $t("estrategiaDigital.section6.profesion") }}
              </p>
              <hr>
              <p
                v-for="(item, index) in texts"
                :key="index"
                class="text-left"
              >
                {{ item }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            imgTutor: require("@/assets/images/digital/anonimo.jpg")
        }
    },
    computed: {
        texts() {
            return this.$t("estrategiaDigital.section6.texts");
        }
    },
}
</script>

<style lang="scss">
  #section6{
    .imagenRedonda{
      border: 2px solid ghostwhite;
      border-radius: 6em;    
      width: 7em;
      height: 7em;
      margin: 0 auto;

      img{
        width: auto;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 6em;   
      }
    }
    .cardGris{
      background: gainsboro;
      border: 0.15em solid ghostwhite;
      padding: 3em;
      text-align: center;
    }
  }
</style>