<template>
  <div
    id="section16"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4">
        <h3 class="text-center mb-3">
          <span
            v-for="(item, index) in title"
            :key="index"
          >
            <span v-if="item.type=='txt'">
              {{ item.value }}
            </span>
            <strong v-if="item.type=='strong'">
              {{ item.value }}
            </strong>
          </span>
        </h3>
        <br>
        <div style="margin: 0 auto;">
          <carousel
            :autoplay="true"
            :autoplay-timeout="2000"
            :autoplay-hover-pause="true"
            :loop="true"
            :per-page-custom="[[360, 1], [480, 1], [768, 2], [980, 3], [1500, 5]]"
          >
            <slide
              v-for="(item, idx) in lists"
              :key="idx"
            >
              <div class="cardd cardd-outline">
                <div class="quoteMargen">
                  <font-awesome-icon
                    :icon="['fas','quote-left']"
                    size="4x"
                  />
                </div>
                <p>
                  "{{ item.text }}"
                </p>
                <hr>
                <strong>
                  {{ item.nombre }}
                </strong>
                <br>
                <i>
                  {{ item.cargo }}
                </i>
                <br>
                <p>
                  <font-awesome-icon :icon="['fas','globe-americas']" />
                  {{ item.origen }}
                </p>
              </div>
            </slide>
          </carousel>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            slide: 0,
            sliding: null
        };
    },
    computed: {
        title(){
            return this.$t("estrategiaDigital.section14.title");
        },
        lists(){
            return this.$t("estrategiaDigital.section14.lists");
        }
    },
}
</script>



<style lang="scss">
  #section16{
    .cardd-outline{
      background-color: rgba($color: #000000, $alpha: 0.1);
      border: 0.15em solid whitesmoke;
      border-radius: 1em;
      padding: 1.2em;
      text-align: center;
      margin: 2em 0.5em;
      height: calc(90%);
    }
    .quoteMargen{
      margin-top: -3em;
      margin-bottom: 0.5em;
    }
  }
</style>