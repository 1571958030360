<template>
  <div
    id="section11"
    class="fondoAzul"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-2">
        <h3 class="text-center">
          <strong>{{ $t("estrategiaDigital.section11.title") }}</strong>
        </h3>
        <br>
        <b-row>
          <b-col
            v-for="(item, index) in lists"
            :key="index"
            md="3"
            sm="6"
          >
            <div class="text-center">
              <b-img
                :src="listsImg[index]"
                style="width: 4em"
              />
              <h2 class="mt-2">
                {{ item.val }}
              </h2>
              <h4>
                {{ item.txt }}
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            listsImg:[
                require("@/assets/images/digital/video-lectures.svg")
                ,require("@/assets/images/digital/tareas.svg")
                ,require("@/assets/images/digital/discussions.svg")
                ,require("@/assets/images/digital/estudios-de-caso.svg")
            ]
        }
    },
    computed: {
        lists() {
            return this.$t("estrategiaDigital.section11.lists");
        }
    },
}
</script>