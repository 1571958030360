<template>
  <div
    id="section3"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container>
        <b-row>
          <b-col
            md="6"
            sm="12"
          >
            <h3>
              <span
                v-for="(item, index) in title1"
                :key="'t' + index"
              >
                <span v-if="item.type == 'txt'">
                  {{ item.value }}
                </span>
                <strong v-if="item.type == 'strong'">
                  {{ item.value }}
                </strong>
              </span>
            </h3>
            <br>
            <p
              v-for="(item, index) in text"
              :key="'p' + index"
            >
              <span
                v-for="(item1, index1) in item.value"
                :key="'ps' + index1"
              >
                <span v-if="item1.type == 'txt'">
                  {{ item1.value }}
                </span>
                <i v-if="item1.type == 'i'">
                  {{ item1.value }}
                </i>
              </span>
            </p>
            <br>
            <div
              v-for="(item, index) in card"
              :key="'d' + index"
              class="cardd cardd-outline mb-3"
            >
              <span
                v-for="(item1, index1) in item.title"
                :key="'ds' + index1"
              >
                <span :class="item1.type">
                  {{ item1.value }}
                </span>
              </span>
              <br>
              <p>
                {{ item.text }}
              </p>
              <p>
                {{ item.fuente }}
              </p>
            </div>
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <div class="cardd cardAzul mb-3">
              <form
                v-if="!sentSuccess"
                id="formulario"
                method="POST"
                action="#"
                @submit.prevent="onSubmit"
              >
                <b-row>
                  <b-col cols="12">
                    <h3 class="mt-3">
                      {{ $t('estrategiaDigital.section3.formulario.title2') }}
                    </h3>
                  </b-col>
                  <b-col cols="12">
                    <b-form-input
                      id="nombre"
                      v-model="formulario.nombre"
                      class="mt-3"
                      name="nombre"
                      :placeholder="$t('estrategiaDigital.section3.formulario.fNombre')"
                      required
                    />
                  </b-col>
                  <b-col cols="12">
                    <b-form-input
                      id="apellido"
                      v-model="formulario.apellido"
                      class="mt-3"
                      name="apellido"
                      :placeholder="$t('estrategiaDigital.section3.formulario.fApellido')"
                      required
                    />
                  </b-col>
                  <b-col cols="12">
                    <b-form-input
                      id="email"
                      v-model="formulario.email"
                      class="mt-3"
                      name="email"
                      type="email"
                      :placeholder="$t('estrategiaDigital.section3.formulario.fEmail')"
                      required
                    />
                  </b-col>
                  <b-col cols="12">
                    <b-form-select
                      id="pais"
                      v-model="formulario.pais"
                      class="mt-3"
                      name="pais"
                      :options="fPais"
                      required
                    />
                  </b-col>
                  <b-col cols="12">
                    <b-form-select
                      id="experiencia"
                      v-model="formulario.experiencia"
                      class="mt-3"
                      name="experiencia"
                      :options="fProfesional"
                      required
                    />
                  </b-col>
                  <b-col cols="12">
                    <b-form-input
                      id="celular"
                      v-model="formulario.celular"
                      class="mt-3"
                      name="celular"
                      :placeholder="$t('estrategiaDigital.section3.formulario.fCelular')"
                      required
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-3"
                  >
                    <input
                      id="acept"
                      v-model="formulario.acept"
                      type="checkbox"
                      required
                      :value="$t('convenio.He_leído')+' '+$t('convenio.políticas_de_privacidad')"
                    >
                    {{ $t('convenio.He_leído') }}<a
                      href="/aviso-legal-privacidad/"
                      target="_blank"
                    >{{ $t('convenio.políticas_de_privacidad') }}</a>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                      class="mt-4 buttonTurqueza"
                      size="lg"
                      block
                      type="submit"
                    >
                      {{ $t('estrategiaDigital.section3.formulario.fbtn') }}
											&nbsp;
                      <font-awesome-icon :icon="['fas', 'download']" />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <p class="mt-4">
                      {{ $t('estrategiaDigital.section3.formulario.fpolitica1') }}
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <b-link
                      class="mt-4 linkWhite"
                      :href="urlPolitica"
                      target="_blank"
                    >
                      {{ $t('estrategiaDigital.section3.formulario.fpolitica2') }}
                    </b-link>
                  </b-col>
                </b-row>
              </form>
              <div
                v-if="sentSuccess"
                class="send-success"
              >
                <h2>{{ $t('common_contact_form.thanks_message') }}</h2>
                <h3 style="color:#fff;">
                  {{ $t('common_contact_form.will_contact') }}
                </h3>
                <div
                  class="contact-info text-center mt-4"
                  style="color:#fff"
                >
                  <h4>Puedes contactarme por</h4>
                  <div class="contact-nfo">
                    <font-awesome-icon
                      icon="phone"
                      class="phone-icon"
                    />
                    <a
                      href="tel:+34928354668"
                      style="color:#fff"
                    >+34 928 354 668</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="cardd cardd-outline cardAzulT mb-3">
              <div class="mb-2">
                <font-awesome-layers class="fa-4x">
                  <font-awesome-icon :icon="['fas', 'redo-alt']" />
                  <font-awesome-icon
                    :icon="['fas', 'dollar-sign']"
                    transform="shrink-11"
                  />
                </font-awesome-layers>
              </div>
              <h3>
                {{ $t('estrategiaDigital.section3.card1.txt1') }}
              </h3>
              <h3>
                <strong>
                  {{ $t('estrategiaDigital.section3.card1.txt2') }}
                </strong>
              </h3>
              <h3>
                <b-link v-scroll-to="'#fcontact'">
                  {{ $t('estrategiaDigital.section3.card1.link') }}
                </b-link>
              </h3>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            formulario: {
                nombre: null,
                apellido: null,
                email: null,
                pais: null,
                experiencia: null,
                celular: null,
                locate: this.$i18n.locale,
                web: window.location.href,
                acept: null
            },
            enviar: 0,
            sentSuccess: false,
            sentFailed: false,
            urlPolitica: '/aviso-legal-privacidad/'
        };
    },
    computed: {
        title1() {
            return this.$t('estrategiaDigital.section3.title1');
        },
        text() {
            return this.$t('estrategiaDigital.section3.text');
        },
        card() {
            return this.$t('estrategiaDigital.section3.card');
        },
        fPais() {
            return this.$t('estrategiaDigital.section3.formulario.fPais');
        },
        fProfesional() {
            return this.$t('estrategiaDigital.section3.formulario.fProfesional');
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.enviar = 0;
            this.axios
                .post('https://www.websincoste.com/index.php', {
                    _nonce: 'f9d8d3d927baca5e53d95c32b41f6f00',
                    action: 'send_universal_form',
                    serialize: [
                        { name: 'Form Type', value: 'ESTRATEGIAS DIGITALES Form' },
                        { name: 'Nombre', value: this.formulario.nombre },
                        { name: 'Email', value: this.formulario.email },
                        { name: 'Apellido', value: this.formulario.apellido },
                        { name: 'Pais', value: this.formulario.pais },
                        { name: 'Experiencia', value: this.formulario.experiencia },
                        { name: 'Celular', value: this.formulario.celular },
                        { name: 'Reference Link', value: window.location.href },
                        { name: 'Aceptación Privacidad RGDP:', value: this.formulario.acept ? this.$t('convenio.He_leído')+' '+this.$t('convenio.políticas_de_privacidad') : false }
                    ]
                })
                .then(response => {
                    if (response.status === 200) {
                        this.sentSuccess = true;
                        this.formulario = {
                            nombre: null,
                            apellido: null,
                            email: null,
                            pais: null,
                            experiencia: null,
                            celular: null,
                            acept: null
                        };
                        setTimeout(() => {
                            this.sentSuccess = false;
                        }, 10000);
                    } else {
                        this.sentFailed = true;
                        let send = this.$t('sendERROR');
                        this.$bvToast.toast(send, {
                            title: this.$t('message.rs'),
                            variant: 'default',
                            autoHideDelay: 3000,
                            solid: true
                        });
                    }
                })
                .catch(error => {
                    this.sentFailed = true;
                    this.sentSuccess = false;
                    console.log(error);
                });
        }
    }
};
</script>
<style lang="scss">
div.send-success {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	height: 100%;
}
#section3 {
	.cardd-outline {
		padding: 1.2em;
		text-align: center;
	}
	.cardAzulT {
		background: rgba(0, 124, 124, 0.7);
	}
	.cardAzul {
		background: rgba(30, 153, 148, 0.8);
		color: whitesmoke;
		padding: 4em 2em;
		text-align: center;
	}
	.linkWhite {
		color: whitesmoke !important;
		text-decoration: underline;
	}
	.buttonTurqueza {
		border-color: whitesmoke !important;
		border-width: 2px !important;
		background-color: transparent !important;
		color: whitesmoke !important;
		margin: 0 auto;
		cursor: pointer;
		transition: 0.3s !important;
	}
	.buttonTurqueza:hover {
		text-decoration: inherit !important;
		transform: scale(1.1);
	}
}
</style>
