<template>
  <div
    id="section13"
    class="fondoGrisOscuro"
  >
    <b-container fluid>
      <b-container class="mt-4 mb-4 text-center">
        <b-row align-v="center">
          <b-col
            md="6"
            sm="12"
          >
            <b-img
              :src="img"
              style="width: 20em"
            />
          </b-col>
          <b-col
            md="6"
            sm="12"
          >
            <h3>
              <strong>
                {{ $t("estrategiaDigital.section13.title") }}
              </strong>
            </h3>
            <p>
              {{ $t("estrategiaDigital.section13.text") }}
            </p>
            <br>
            <b-button
              v-scroll-to="'#contacto'"
              class="buttonWhite-outline"
            >
              {{ $t("estrategiaDigital.section13.btn") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    data() {
        return {
            img: require("@/assets/images/digital/big-certificate-3.png")
        }
    },
}
</script>