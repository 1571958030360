<template>
  <div
    id="section8"
    class="fondoGrisClaro"
  >
    <b-container fluid>
      <b-container class="mt-3 mb-3">
        <h3 class="text-center">
          <strong>
            {{ $t("estrategiaDigital.section8.title") }}
          </strong>
        </h3>
        <br>
        <div role="tablist">
          <b-card
            v-for="(item, index) in lists"
            :key="index"
            no-body
            class="mb-1"
          >
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
            >
              <b-button
                v-b-toggle="'accordion-'+index"
                block
                href="#"
                class="buttonWhite-outline1"
              >
                <b-row
                  align-h="center"
                  align-v="center"
                >
                  <b-col
                    md="2"
                    sm="12"
                    class="text-center"
                  >
                    {{ item.modulo }}
                  </b-col>
                  <b-col
                    md="9"
                    sm="12"
                    class="text-left"
                  >
                    {{ item.title }}
                  </b-col>
                  <b-col
                    md="1"
                    sm="12"
                    class="text-center"
                  >
                    <font-awesome-icon :icon="['fas', 'caret-square-down']" />
                  </b-col>
                </b-row>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'accordion-'+index"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <div
                    v-for="(item1, index1) in item.contenido"
                    :key="index1"
                  >
                    <strong>
                      {{ item1.title }}
                    </strong>
                    <br>
                    <ul>
                      <li
                        v-for="(item2, index2) in item1.list"
                        :key="index2"
                        class="ml-4"
                      >
                        {{ item2 }}
                      </li>
                    </ul>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
    computed: {
        lists() {
            return this.$t("estrategiaDigital.section8.lists");
        }
    },
}
</script>