<template>
  <div id="digital">
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
    <Section7 />
    <Section8 />
    <Section9 />
    <Section10 />
    <Section11 />
    <Section12 />
    <Section13 />
    <Section14 />
    <Section15 />
    <Section16 />
  </div>
</template>

<script>
import Section1 from './components/estrategia/Section1';
import Section2 from './components/estrategia/Section2';
import Section3 from './components/estrategia/Section3';
import Section4 from './components/estrategia/Section4';
import Section5 from './components/estrategia/Section5';
import Section6 from './components/estrategia/Section6';
import Section7 from './components/estrategia/Section7';
import Section8 from './components/estrategia/Section8';
import Section9 from './components/estrategia/Section9';
import Section10 from './components/estrategia/Section10';
import Section11 from './components/estrategia/Section11';
import Section12 from './components/estrategia/Section12';
import Section13 from './components/estrategia/Section13';
import Section14 from './components/estrategia/Section14';
import Section15 from './components/estrategia/Section15';
import Section16 from './components/estrategia/Section16';

export default {
    name: 'Digital',
    components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Section6,
        Section7,
        Section8,
        Section9,
        Section10,
        Section11,
        Section12,
        Section13,
        Section14,
        Section15,
        Section16
    },
    head: {
        title: {
            inner: 'Estrategia Digital'
        }
    }
};
</script>
<style scoped lang="scss">
@import '../../assets/css/stlProyecto.css';
</style>
